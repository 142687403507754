import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { IdentityService } from '@dmc-ng/authentication';
import {
  DIGIT_REGEX,
  InputComponent,
  LOWER_CASE_REGEX,
  SPECIAL_CHAR_REGEX,
  UPPER_CASE_REGEX,
} from '@dmc-ng/ui/form';
import { Subject, takeUntil } from 'rxjs';

import { ResetPasswordResolvedData } from '../../utils/reset-password.resolver';
import { ResetPasswordReactiveFormModel } from '../models/reset-password.model';

@Component({
  selector: 'dmc-console-reset-password',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatButtonModule,
    InputComponent,
    MatChipsModule,
  ],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm: FormGroup<ResetPasswordReactiveFormModel> =
    this.formBuilder.group(
      {
        newPassword: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(UPPER_CASE_REGEX),
          Validators.pattern(LOWER_CASE_REGEX),
          Validators.pattern(DIGIT_REGEX),
          Validators.pattern(SPECIAL_CHAR_REGEX),
        ]),
        confirmPassword: this.formBuilder.control('', Validators.required),
      },
      { validators: this.passwordMatchValidator },
    );

  canShowNewPassword = true;
  canShowConfirmPassword = true;

  isMinLengthValid = false;
  isUppercaseValid = false;
  isLowercaseValid = false;
  isNumberValid = false;
  isSpecialCharValid = false;

  isCodeValid = false;
  isPasswordSuccessfullyUpdated = false;
  isPasswordFailedToBeUpdated = false;

  private actionCode: string | null = null;
  private continueUrl: string | null = null;
  private isDestroyed$: Subject<void> = new Subject();

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private identityService: IdentityService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const resolvedData: ResetPasswordResolvedData =
      this.route.snapshot.data['resetPasswordData'];

    this.actionCode = resolvedData.actionCode;
    this.continueUrl = resolvedData.continueUrl;
    this.isCodeValid = resolvedData.isValid;

    this.resetPasswordForm.controls.newPassword.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((value: string) => {
        this.isMinLengthValid = value.length >= 8;
        this.isUppercaseValid = UPPER_CASE_REGEX.test(value);
        this.isLowercaseValid = LOWER_CASE_REGEX.test(value);
        this.isNumberValid = DIGIT_REGEX.test(value);
        this.isSpecialCharValid = SPECIAL_CHAR_REGEX.test(value);
      });
  }

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const formGroup = control as FormGroup<ResetPasswordReactiveFormModel>;
    const passwordControl = formGroup.controls.newPassword;
    const confirmPasswordControl = formGroup.controls.confirmPassword;

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    const password = passwordControl.value;
    const confirmPassword = confirmPasswordControl.value;

    if (password !== confirmPassword) {
      confirmPasswordControl.setErrors({ mismatch: true });
    } else {
      confirmPasswordControl.setErrors(null);
    }

    return null;
  }

  onSubmit(): void {
    if (this.resetPasswordForm.valid && this.actionCode) {
      const newPassword = this.resetPasswordForm.controls.newPassword.value;
      this.identityService
        .confirmPasswordReset(this.actionCode, newPassword)
        .then(() => {
          this.isPasswordSuccessfullyUpdated = true;
        })
        .catch((error) => {
          if (error.code === 'auth/expired-action-code') {
            this.isCodeValid = false;
          } else {
            this.isPasswordFailedToBeUpdated = true;
          }
        });
    }
  }

  showNewPassword(): void {
    this.canShowNewPassword = !this.canShowNewPassword;
  }

  showConfirmPassword(): void {
    this.canShowConfirmPassword = !this.canShowConfirmPassword;
  }

  redirectToLogin(): void {
    this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }
}
