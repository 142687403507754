import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { IdentityService } from '@dmc-ng/authentication';
import { Observable, of, Subscriber } from 'rxjs';

export interface ResetPasswordResolvedData {
  actionCode: string;
  continueUrl: string;
  isValid: boolean;
}

export const resetPasswordResolver: ResolveFn<{
  actionCode: string;
  isValid: boolean;
}> = (
  route: ActivatedRouteSnapshot,
): Observable<{ actionCode: string; isValid: boolean }> => {
  const identityService = inject(IdentityService);

  const actionCode = route.queryParamMap.get('oobCode');
  const continueUrl = route.queryParamMap.get('continueUrl') ?? '';

  if (!actionCode) {
    return of({ actionCode: '', continueUrl, isValid: false });
  }

  return new Observable((observer: Subscriber<ResetPasswordResolvedData>) => {
    identityService
      .verifyPasswordResetCode(actionCode)
      .then(() => {
        observer.next({ actionCode, continueUrl, isValid: true });
        observer.complete();
      })
      .catch(() => {
        observer.next({ actionCode: '', continueUrl, isValid: false });
        observer.complete();
      });
  });
};
